<template>
    <span />
</template>

<script>
import MapService from 'services/mapservice';
import { debounce } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        layerId: {
            type: String,
            required: true,
        },
        sourceId: {
            type: String,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            sourceCoords: [],
            draggedFeatureId: null,
            dragTime: 0,
        };
    },
    beforeDestroy() {
        this.deRegisterEvents();
    },
    watch: {
        editing() {
            if (this.editing) {
                this.registerEvents();
            } else {
                this.deRegisterEvents();
            }
        },
    },
    computed: {
        ...mapGetters('annotation', ['editing', 'annotation']),
        canvas() {
            return this.map.getCanvasContainer();
        },
        geoJson() {
            return this.layer.geoJson;
        },
    },
    methods: {
        ...mapActions('map', ['updateAnnotation', 'saveMapWithoutOverwriting']),
        deRegisterEvents() {
            MapService.deRegisterMarkerEvent('mouseenter', this.layerId);
            MapService.deRegisterMarkerEvent('mouseleave', this.layerId);
            MapService.deRegisterMarkerEvent('mousedown', this.layerId);
            MapService.deRegisterMarkerEvent('touchstart', this.layerId);
        },
        registerEvents() {
            MapService.registerMarkerEvent('mouseenter', this.layerId, this.onCircleEnter);
            MapService.registerMarkerEvent('mouseleave', this.layerId, this.onCircleLeave);
            MapService.registerMarkerEvent('mousedown', this.layerId, this.onCircleMouseDown);
            MapService.registerMarkerEvent('touchstart', this.layerId, this.onCircleTouchStart);
        },
        onCircleEnter() {
            this.canvas.style.cursor = 'move';
        },
        onCircleLeave() {
            this.canvas.style.cursor = '';
        },
        onCircleMouseDown(e) {
            if (e.features[0].properties.name !== this.annotation.name) {
                return;
            }
            this.draggedFeatureId = e.features[0].id;
            // Prevent the default map drag behavior.
            e.preventDefault();
            this.canvas.style.cursor = 'grab';
            this.dragTime = Date.now();
            // Add listeners
            this.map.on('mousemove', this.onMove);
            this.map.once('mouseup', this.onCircleUp);
        },
        onCircleUp() {
            this.canvas.style.cursor = '';
            this.map.off('mousemove', this.onMove);
            this.map.off('touchmove', this.onMove);
            this.currentFeatureId = null;
            // Updating with identical sourceCoords causes annotation to disappear
            if (Date.now() - this.dragTime >= 250) {
                this.updateCoords(this.sourceCoords);
            }
            this.sourceCoords = [];
        },
        onMove(e) {
            this.sourceCoords = e.lngLat;
            // Set a UI indicator for dragging.
            this.canvas.style.cursor = 'grabbing';
            this.geoJson.features[this.draggedFeatureId].geometry.coordinates = [
                this.sourceCoords.lng,
                this.sourceCoords.lat,
            ];
            this.map.getSource(this.sourceId).setData(this.geoJson);
        },
        updateCoords: debounce(function (coords) {
            this.updateAnnotation({
                layer: this.layer,
                annotation: {
                    ...this.annotation,
                    coordinate: {
                        latitude: coords.lat,
                        longitude: coords.lng,
                    },
                },
                index: this.draggedFeatureId,
            });
        }, 1000),
        onCircleTouchStart(e) {
            if (e.points.length !== 1) return;
            // Prevent the default map drag behavior.
            e.preventDefault();
            this.map.on('touchmove', this.onMove);
            this.map.once('touchend', this.onCircleUp);
        },
        // dragging logic
    },
};
</script>
