<template>
    <draggable-marker :layer-id="layerId" :source-id="sourceId" :map="map.map" :layer="clonedLayer" />
</template>

<script>
import { cloneDeep } from 'utils/lodash';
import { modifyFeatures } from 'utils/helpers';
import MapService from 'services/mapservice';
import Const from './const';
import DraggableMarker from './components/annotations/draggable-marker';
import { mapGetters } from 'vuex';
export default {
    components: {
        DraggableMarker,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paint: {
                'text-color': ['case', ['==', ['feature-state', 'editing'], 1], '#FFFF00', '#FFF'],
            },
            layout: {
                visibility: 'visible',
                'text-field': [
                    'number-format',
                    ['get', 'order'],
                    {
                        'min-fraction-digits': 0,
                        'max-fraction-digits': 0,
                    },
                ],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-size': 20,
            },
        };
    },
    watch: {
        jsonSource: 'onJsonSourceChange',
    },
    computed: {
        ...mapGetters('map', ['isChangingProvider']),
        layerId() {
            return this.layer.name + Const.AREA_TOUR_LAYER_ID;
        },
        sourceId() {
            return this.layer.name + Const.AREA_TOUR_SOURCE_ID;
        },
        jsonSource() {
            return {
                ...this.layer.geoJson,
                features: modifyFeatures(this.layer.geoJson?.features, {
                    layerType: 'area_tour',
                    layer: this.layer,
                }),
            };
        },
        clonedLayer() {
            return cloneDeep(this.layer);
        },
    },
    created() {
        this.createLayer();
    },
    async beforeDestroy() {
        if (this.isChangingProvider) {
            return;
        }

        await this.removeLayer();
    },
    methods: {
        onJsonSourceChange() {
            MapService.updateMarkerCircleLayerIcon(this.layerId, this.layer.icon);
            MapService.updateClusterCircleLayerIcon(this.layerId, this.layer.icon);
            MapService.updateSource(this.sourceId, this.jsonSource);
        },
        async createLayer() {
            await MapService.addClusteredFeatureCollection(this.sourceId, this.jsonSource);
            MapService.addClusterCircleLayer(this.sourceId, this.layerId, this.layer.icon);
            MapService.addClusterCircleTextLayer(this.sourceId, this.layerId);
            MapService.addMarkerCircleLayer(this.sourceId, this.layerId, this.layer.icon);
            MapService.addMarkerCircleTextLayer(this.sourceId, this.layerId, this.paint, this.layout);
            MapService.addMarkerCircleRecommendedIconLayer(this.sourceId, this.layerId, this.icon);
            MapService.addMarkerCircleFavoriteIconLayer(this.sourceId, this.layerId, this.icon);
        },
        async removeLayer() {
            await MapService.removeMarkerCircleRecommendedIconLayer(this.layerId);
            await MapService.removeMarkerCircleFavoriteIconLayer(this.layerId);
            await MapService.removeMarkerCircleTextLayer(this.layerId);
            await MapService.removeMarkerCircleLayer(this.layerId);
            await MapService.removeClusterCircleTextLayer(this.layerId);
            await MapService.removeClusterCircleLayer(this.layerId);
            await MapService.removeSource(this.sourceId);
        },
    },
};
</script>
